<template>
  <div>
    <div>
      <v-expansion-panels class="mt-3 mb-5">
        <v-expansion-panel>
          <v-expansion-panel-header class="text my-n4" :name="name" :data-cy="name">
            {{ label }}
            <div class="mb-6 ml-3"><DynamicInfoDialog
              :info="doc"
              :headerTitle="label"
            ></DynamicInfoDialog></div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-for="(node, index) in nodes" :key="index">
              <NestedFormSingle
                v-if="node.type === 'dict'"
                :data-cy="node.name"
                :label="node.label"
                :name="node.name"
                :required="node.required"
                :valuePath="[...valuePath, node.name]"
                :nodes="node.nodes"
              >
              </NestedFormSingle>
              <div v-else>
                <FormField
                  :data-cy="node.name"
                  :valuePath="[...valuePath, node.name]"
                  :data="node"
                  :isDict="isDict"
                  v-model="localValue"
                >
                </FormField>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
const DynamicInfoDialog = () => import('./DynamicInfoDialog.vue')
import { mapMutations, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'

export default {
  name: 'NestedFormSingle',

  components: {
    FormField: () => import('./FormField.vue'),
    DynamicInfoDialog,
  },

  data: () => ({
    isDict: true,
  }),

  props: {
    nodes: Array,
    label: String,
    name: String,
    doc: String,
    required: Boolean,
    valuePath: Array,
    value: [Object, String, Number, Array],
  },

  methods: {
    ...mapMutations('dynamicForms', ['setDictValue']),

    clear() {
      this.$refs.form.reset()
    },
  },

  computed: {
    ...mapGetters('dynamicForms', ['getNestedValue']),

    localValue: {
      get: function () {
        if (this.valuePath.length > 1) {
          return this.getNestedValue(cloneDeep(this.valuePath))
        }
        return this.value
      },
      set: function (value) {
        this.setDictValue([cloneDeep(this.valuePath), value])
      },
    },
  },
}
</script>

<style scoped></style>
